// Entry point for the build script in your package.json

import 'stylesheets/application.scss'

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import './channels'
import 'jquery'
import 'bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
